import React from 'react';
import Layout from 'src/components/layouts/default';
import AppLink from 'src/components/base/appLink';
import * as classes from './404.module.scss';

const NotFoundPage = () => (
  <Layout pageTitle="Not Found">
    <div className={classes.body}>
      <div>We can’t find the page you’re looking for.</div>
      <div>
        <AppLink to="https://www.boulder.care" className="button">
          Go home
        </AppLink>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
